import logo from './logo.svg';
import './App.css';
import {
    Box,
    FormControl,
    FormHelperText,
    FormLabel,
    Heading,
    Input,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper, Table, Tbody, Td,
    Text, Th, Thead, Tr,
    useTheme
} from '@chakra-ui/react'
import {useState} from "react";

function App() {
    const theme = useTheme();

    const [personalIncome, setPersonalIncome] = useState(50000);
    const [fixedCosts, setFixedCosts] = useState(0);
    const [perUserCost, setPerUserCost] = useState(0);
    const [productCost, setProductCost] = useState(0);
    const [companySavings, setCompanySavings] = useState(0);

    const handleCompanySavingsChange = (_, v) => {
        if (!v) {
            setCompanySavings(0);
            return;
        }
        setCompanySavings(v);
    }

    const handleProductCostChange = (_, v) => {
        if (!v) {
            setProductCost(0);
            return;
        }
        setProductCost(v);
    }

    const handlePersonalIncomeChange = (_, v) => {
        if (!v) {
            setPersonalIncome(0);
            return;
        }
        setPersonalIncome(v);
    }

    const calculatePerUnitMargin = () => {
        return productCost - perUserCost;
    }

    const handleFixedCostsChange = (_, v) => {
        if (!v) {
            setFixedCosts(0);
            return;
        }
        setFixedCosts(v);
    }

    const handlePerUserCostChange = (_, v) => {
        if (!v) {
            setPerUserCost(0);
            return;
        }
        setPerUserCost(v);
    }

    const calculateRequiredRevenue = () => {
        // return personalIncome / (grossMargin / 100);
    }

    const calculateRequiredUsers = () => {
        let userCount = 0;
        // consider product cost, per user cost, and fixed costs, and required revenue
        let requiredRevenue = calculateRequiredRevenue();
        let annualProductCost = productCost * 12;
        let annualPerUserCost = perUserCost * 12;
        let annualFixedCosts = fixedCosts * 12;
        let annualGrossMargin = requiredRevenue - annualProductCost - annualPerUserCost - annualFixedCosts;
        let monthlyGrossMargin = annualGrossMargin / 12;
        let monthlyGrossMarginPerUser = monthlyGrossMargin / 100;
        userCount = requiredRevenue / monthlyGrossMarginPerUser;
        return userCount;
    }

    const calculateRequiredRevenueARRForGivenSalary = (
        // personalIncome,
        // companySavings,
        // fixedCosts,
        // perUserCost,
        // productCost
    ) => {
        const targetSalary = personalIncome;
        // Calculate target gross profit considering salary and company savings (company savings is a percentage of gross profit)
        // const companySavingsGoal = 0;
        // const targetGrossProfit = targetSalary + (targetSalary * (companySavings / 100));
        const companySavingsPercentage  = companySavings / 100;
        const targetGrossProfit = targetSalary + (targetSalary * companySavingsPercentage);
        // Calculate costs first - fixed costs and per user costs
        let annualFixedCosts = fixedCosts * 12;
        let annualPerUserCosts = perUserCost * 12;
        // Calculate required users to cover the remaining amount considering per user costs and product cost
        let annualGrossProfitToCover = targetGrossProfit + annualFixedCosts;
        const earningsPerUserPerYear = (productCost - perUserCost) * 12;
        const requiredUsers = annualGrossProfitToCover / earningsPerUserPerYear;
        // Calculate required revenue to get target gross profit including fixed costs
        let requiredRevenue = (requiredUsers * productCost * 12) + annualFixedCosts;
        return Math.ceil(requiredRevenue);
    };

    const calculateRequiredNumberOfUsers = () => {
        const requiredRevenue = calculateRequiredRevenueARRForGivenSalary();
        const requiredUsers = requiredRevenue / (productCost * 12);
        return Math.ceil(requiredUsers);
    }

    const calculateGrossProfit = () => {
        const revenue = calculateRequiredRevenueARRForGivenSalary();
        const costs = (fixedCosts + ((perUserCost) * calculateRequiredNumberOfUsers())) * 12;
        console.log("costs:::", costs);
        return revenue - costs;
    }

    const grossProfit = calculateGrossProfit();

    const calculateNetProfit = () => {
        const netProfit = grossProfit - personalIncome;
        return netProfit;
    }

    const netProfit = calculateNetProfit();

    const requiredArr = calculateRequiredRevenueARRForGivenSalary();
    const requiredMrr = Math.ceil(requiredArr / 12);
    const requiredUserCount = calculateRequiredNumberOfUsers();

    const perUserGrossMargin = calculatePerUnitMargin();
    const perUserNetMargin = Math.ceil((netProfit / 12) / requiredUserCount);


    return (<div>
        <Box maxW="960px" mx="auto" mt={"64px"} px={'16'}>
            <Heading>Indie Calculator 💰</Heading>
            <Text>Indie Calculator is a tool for <em style={{color: theme.colors.gray["500"]}}>(aspiring)</em> indie
                hackers to calculate what revenue they should be targeting</Text>

            <Box mt={"48px"}>
                <FormControl>
                    <FormLabel>How much would you like to pay yourself annually?</FormLabel>
                    <NumberInput value={personalIncome} onChange={handlePersonalIncomeChange}>
                        <NumberInputField/>
                        <NumberInputStepper>
                            <NumberIncrementStepper/>
                            <NumberDecrementStepper/>
                        </NumberInputStepper>
                    </NumberInput>
                    <FormHelperText>Think of this as what you might want your <em>salary</em> to be, or what it
                        already is. All values are USD.</FormHelperText>
                </FormControl>

                {/* Add Fixed Costs number input */}
                <FormControl mt={"32px"}>
                    <FormLabel>What will your fixed costs be? (monthly)</FormLabel>
                    <NumberInput value={fixedCosts} onChange={handleFixedCostsChange}>
                        <NumberInputField/>
                        <NumberInputStepper>
                            <NumberIncrementStepper/>
                            <NumberDecrementStepper/>
                        </NumberInputStepper>
                    </NumberInput>
                    <FormHelperText>
                        These are costs that you would have to pay regardless of how much revenue
                        you're making. For example, landing page hosting, a domain name, a customer support tool,
                        etc.
                        Make a best guess if you're not sure. $100 per month is a good starting point for most indie
                        hackers.
                    </FormHelperText>
                </FormControl>

                {/* Add Per User Costs number input */}
                <FormControl mt={"32px"}>
                    <FormLabel>What are your per user (variable) costs? (monthly, per user)</FormLabel>
                    <NumberInput value={perUserCost} onChange={handlePerUserCostChange}>
                        <NumberInputField/>
                        <NumberInputStepper>
                            <NumberIncrementStepper/>
                            <NumberDecrementStepper/>
                        </NumberInputStepper>
                    </NumberInput>
                    <FormHelperText>
                        These are costs that you would have to pay for each user that you have. For example
                        <ul>
                            <li>
                                if you
                                expect to store
                                1GB of data per user, and your hosting charges $0.10 per GB, then this number would
                                be
                                $0.10.
                            </li>
                            <li>
                                If you also expect to send 100 emails per user per month, and your email provider
                                charges
                                $0.01
                                per email, then you would add $1.00 to this number.
                            </li>
                            <li>
                                If you think you can host 100 users on a $10/mo server, then you would add $0.10 to
                                this
                                number.
                            </li>
                        </ul>

                        Based on the above examples, this number would be $1.20. If you had 100 users, then your
                        total per user costs would be $120 per month.<br/>
                        Make a best guess if you're not sure.
                    </FormHelperText>
                </FormControl>

                {/* Add Product Cost number input */}
                <FormControl mt={"32px"}>
                    <FormLabel>What do you plan to charge for your product? (monthly)</FormLabel>
                    <NumberInput value={productCost} onChange={handleProductCostChange}>
                        <NumberInputField/>
                        <NumberInputStepper>
                            <NumberIncrementStepper/>
                            <NumberDecrementStepper/>
                        </NumberInputStepper>
                    </NumberInput>
                    <FormHelperText>
                        This is the amount that you plan to charge your customers. If you plan to charge $10 per
                        month,
                        then this number would be $10. If you plan to charge $100 per year, then this number would
                        be
                        $8.33.
                    </FormHelperText>
                </FormControl>

                {/* Add company savings number input */}
                <FormControl mt={"32px"}>
                    <FormLabel>How much would you like to save for your company? (percentage)</FormLabel>
                    <NumberInput value={companySavings} onChange={handleCompanySavingsChange} min={0} max={1000}>
                        <NumberInputField/>
                        <NumberInputStepper>
                            <NumberIncrementStepper/>
                            <NumberDecrementStepper/>
                        </NumberInputStepper>
                    </NumberInput>
                    <FormHelperText>
                        This is the amount that you plan to save for your company to reinvest or save for a rainy day.
                        If you plan to save 10% of your self pay, then this number would be 10%.
                    </FormHelperText>
                </FormControl>


            </Box>


            {/*<Box m={"32px 0"}>*/}
            {/*    <Heading size={"md"}>Your Required ARR is ${requiredArr}</Heading>*/}
            {/*    <Heading size={"md"}>Your Required MRR is ${requiredMrr}</Heading>*/}
            {/*    <Heading size={"md"}>You would need {requiredUserCount} users to make your*/}
            {/*        required revenue</Heading>*/}
            {/*    <Heading size={"md"}>*/}
            {/*        You would earn ${Math.ceil(calculatePerUnitMargin())} per user (excluding fixed costs)*/}
            {/*    </Heading>*/}
            {/*    <Heading size={"md"}>*/}
            {/*        Your gross profit would be ${Math.ceil(grossProfit)} per year*/}
            {/*    </Heading>*/}
            {/*    <Heading size={"md"}>*/}
            {/*        Your net profit would be ${Math.ceil(netProfit)} per year*/}
            {/*    </Heading>*/}
            {/*</Box>*/}

            <Box m={"32px 0"}>
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>Head</Th>
                            <Th>Amount</Th>
                            {/*<Th>Profit</Th>*/}
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>Annual Recurring Revenue (ARR)</Td>
                            <Td>${requiredArr}</Td>
                        </Tr>
                        <Tr>
                            <Td>Monthly Recurring Revenue (MRR)</Td>
                            <Td>${requiredMrr}</Td>
                        </Tr>
                        <Tr>
                            <Td>Required User Count</Td>
                            <Td>{requiredUserCount}</Td>
                        </Tr>
                        <Tr>
                            <Td>Per User Gross Margin</Td>
                            <Td>${perUserGrossMargin}</Td>
                        </Tr>
                        <Tr>
                            <Td>Per User Net Margin</Td>
                            <Td>${perUserNetMargin}</Td>
                        </Tr>
                        <Tr>
                            <Td>Gross Profit</Td>
                            <Td>${grossProfit}</Td>
                        </Tr>
                        <Tr>
                            <Td>Your Self-Pay</Td>
                            <Td>${personalIncome}</Td>
                        </Tr>
                        <Tr>
                            <Td>Net Profit (Gross Profit - Self-Pay)</Td>
                            <Td>${netProfit}</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </Box>

        </Box>
    </div>);
}

export default App;
